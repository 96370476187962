<template>
  <ArchivesTable
    :archives="archives"
    :loading="loading"
  >
    <template #rowActions="{ item }">
      <VTooltip
        v-if="isShowCancelBtn(item.status)"
        left
      >
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click="cancelArchive(item.id)"
          >
            <VIcon small>
              fal fa-ban
            </VIcon>
          </VBtn>
        </template>
        <span>Отмена</span>
      </VTooltip>
      <VTooltip
        v-if="isShowRestartBtn(item.status)"
        left
      >
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click="restartArchive(item.id)"
          >
            <VIcon small>
              fal fa-sync
            </VIcon>
          </VBtn>
        </template>
        <span>Рестарт</span>
      </VTooltip>
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_ENGAGEMENT_REPORTS_ARCHIVES_VIEW, params : { archiveId : item.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>
        <span>Просмотр</span>
      </VTooltip>
    </template>
  </ArchivesTable>
</template>

<script>
import ArchivesTable from './ArchivesTable.vue';

export default {
  name: 'ArchivesTableContainer',

  components: {
    ArchivesTable,
  },

  inject: ['Names'],

  data() {
    return {
      archives: [],
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    isShowCancelBtn(status) {
      return !['done', 'cancelled'].includes(status);
    },
    isShowRestartBtn(status) {
      return !['init', 'done'].includes(status);
    },
    async fetch() {
      try {
        this.loading = true;
        const { archives } = await this.$di.api.ReportServiceApi.reportsArchivesGet();
        this.archives = archives;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async restartArchive(id) {
      try {
        this.loading = true;
        await this.$di.api.ReportServiceApi.reportsArchiveRestartPost(id);
        await this.$nextTick();
        await this.fetch();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async cancelArchive(id) {
      try {
        this.loading = true;
        await this.$di.api.ReportServiceApi.reportsArchiveCancelPost(id);
        await this.$nextTick();
        await this.fetch();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
