var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ArchivesTable',{attrs:{"archives":_vm.archives,"loading":_vm.loading},scopedSlots:_vm._u([{key:"rowActions",fn:function(ref){
var item = ref.item;
return [(_vm.isShowCancelBtn(item.status))?_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.cancelArchive(item.id)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-ban ")])],1)]}}],null,true)},[_c('span',[_vm._v("Отмена")])]):_vm._e(),(_vm.isShowRestartBtn(item.status))?_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.restartArchive(item.id)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-sync ")])],1)]}}],null,true)},[_c('span',[_vm._v("Рестарт")])]):_vm._e(),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"blue","to":{ name : _vm.Names.R_ENGAGEMENT_REPORTS_ARCHIVES_VIEW, params : { archiveId : item.id } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }